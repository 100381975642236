import { useGoogleLogin } from "@react-oauth/google";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Outlet } from "react-router-dom";
import GoogleIcon from "../../assets/icons/google";
import Loader from "../../component/common/loader";
import LogoIcon from "../../assets/icons/logoIcon";
import { Google } from "../../constants";
import { getToken, isAdmin, setToken } from "../../utils";
import { authenticateWithGoogle } from "../../services";
import LoginBg from "../../assets/images/login-bg.jpg";
import MeetingDetailImg from "../../assets/images/meeting-detail.png";
import { appConfig } from "../../constants";

function Login({ isLogin }) {
  const [loading, setLoading] = useState(false);
  const [loginPage, setLoginPage] = useState(isLogin ?? false);
  const navigate = useNavigate();

  const partnerStackSignUp = useCallback((user) => {
    const createSignup = () => {
      const growsumo = window.growsumo;

      if (!growsumo) {
        setTimeout(createSignup, 1000);
        return;
      }

      try {
        const { name, email, stripeCustomerId } = user;
        // eslint-disable-next-line no-undef
        growsumo.data.name = name;
        // eslint-disable-next-line no-undef
        growsumo.data.email = email;
        // eslint-disable-next-line no-undef
        growsumo.data.customer_key = email;
        // eslint-disable-next-line no-undef
        growsumo.data.provider_key = stripeCustomerId;
        // eslint-disable-next-line no-undef
        growsumo.createSignup();
      } catch (error) {
        console.error("PartnerStack create sign up error:", error);
      }
    };

    createSignup();
  }, []);

  const handleLoginSuccess = async (tokenResponse) => {
    setLoading(true);
    try {
      const res = await authenticateWithGoogle(tokenResponse.code);

      const { user, accessToken } = res.data;

      partnerStackSignUp(user);

      setToken(accessToken);
      toast.success(res.message);
      setLoading(false);

      localStorage.setItem("email", user.email);

      navigate("/dashboard");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  window.handleLoginSuccess = handleLoginSuccess;

  const handleLoginError = async (error) => {
    console.error("Login failed. Error: ", error);
    toast.error("Login failed");
    setLoading(false);
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    scope: Google.SCOPE,
    onSuccess: handleLoginSuccess,
    onError: handleLoginError,
    overrideScope: true,
  });

  useEffect(() => {
    if (getToken()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const bgStyle = {
    backgroundImage: `url(${LoginBg}), linear-gradient(rgba(7, 4, 102, .9), rgba(7, 4, 102, .9))`,
    backgroundBlendMode: "overlay",
    color: "red",
  };

  return (
    <div className="vh-min-100 tw-flex tw-justify-center tw-bg-gradient-to-t tw-from-palette-blue tw-from-32% tw-to-palette-primary tw-to-100%">
      <div className="tw-flex tw-flex-col tw-items-end tw-justify-center tw-grow tw-srink tw-max-w-[500px] tw-p-0 md:tw-p-8">
        <div className="tw-w-0 lg:tw-w-full tw-overflow-visible">
          <div className="tw-p-12 tw-bg-white tw-rounded-2xl tw-z-20 tw-relative tw-min-w-[500px] tw-max-w-[80vw] md:-tw-mr-[100px]">
            <div className="tw-p-8">
              <form className="form w-100">
                <div className="text-center">
                  <LogoIcon />
                  <h1 className="tw-text-left text-dark fw-bolder tw-mt-12 tw-mb-6">
                    Make that meeting an email
                  </h1>
                  <div className="d-flex justify-content-center mt-10">
                    <button
                      type="button"
                      className="google-btn bg-transparent"
                      onClick={() => login()}
                      disabled={loading}
                    >
                      <span>
                        <GoogleIcon />
                        Sign in with Google
                        {loading && <Loader />}
                      </span>
                    </button>
                  </div>
                  <div className="flex justify-center mt-2 w-100 fw-bold">
                    <p onClick={() => setLoginPage(!loginPage)}>
                      {loginPage
                        ? "Don't have an account yet? "
                        : "Already have an account? "}
                      <span className="text-primary fs-5 cursor-pointer">
                        {loginPage ? "Register now" : "Sign in"}
                      </span>
                    </p>
                  </div>
                  <div className="tw-mt-10">
                    <p className="tw-m-0">
                      By signing up, I agree to Calendyze{" "}
                      <a
                        href="https://calendyze.com/terms-conditions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms &amp; Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://calendyze.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-grow tw-p-8 tw-max-w-screen-md">
        <div
          className="tw-rounded-3xl tw-p-12 tw-w-full tw-h-full tw-bg-cover tw-bg-center tw-flex tw-items-center tw-justify-center"
          style={bgStyle}
        >
          <img
            src={MeetingDetailImg}
            alt="Meeting"
            className="tw-w-full tw-max-w-[440px]"
          />
        </div>
      </div>
      <Outlet />
      {/* <div
        id="g_id_onload"
        data-client_id={appConfig.google.clientId}
        data-context="signin"
        data-callback="handleLoginSuccess"
        data-close_on_tap_outside="false"
        data-itp_support="true"
      ></div> */}
    </div>
  );
}
export default Login;
